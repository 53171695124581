import { useSelector } from "react-redux";

const canAccess = () => {
	const thisRole = useSelector(state => state._authSlice?.user?.role);
	if (thisRole !== "administrator") {
		return false;
	}
	return true;
};

export default canAccess;
