import { createSlice } from "@reduxjs/toolkit";

// Import Own Components
import { modalsList } from "Components/Modals";

const initialState = {
	props  : {},
	modals : Object.keys(modalsList).reduce((acc, name) => {
		acc[name] = false;

		return acc;
	}, {}),
};

const ModalsSlice = createSlice({
	name     : "modals",
	initialState,
	reducers : {
		open : (state, action) => {
			const { name, props } = action.payload ?? {};

			if (!(name in state.modals)) return state;

			state.props = props ?? {};

			state.modals[name] = true;
		},
		close : (state, action) => {
			const { name } = action.payload ?? {};

			if (!(name in state.modals)) return state;

			state.props        = {};
			state.modals[name] = false;
		},
		toggle : (state, action) => {
			const { name } = action.payload ?? {};

			if (!(name in state.modals)) return state;

			const prevValue = state.modals[name];

			// If closing, also remove the props.
			if (!prevValue) state.props = {};

			state.modals[name] = !prevValue;
		},
	},
});

export default ModalsSlice;
