import { bindActionCreators } from "redux";

/**
 * Binds all actions to the dispatch and uncapitalize them to use in the component props
 *
 * @author Yael Mártin A. Alcalá León <yael.alcalla@gmail.com>
 * @param actions
 * @example
 * // Returns dispatch => ({ alertSlice : bindActionCreators(AlertSlice.actions, dispatch) });
 * const mapDispatchToProps = bindAll({ AlertSlice });
 */
const bindAll = actions => (dispatch) => {
	const uncapitalize = str => str.charAt(0).toLowerCase() + str.slice(1);

	return Object.keys(actions).reduce((acc, actionName) => {
		const action = actions[actionName];

		acc[uncapitalize(actionName)] = bindActionCreators(action?.actions ?? action, dispatch);

		return acc;
	}, {});
};

export default bindAll;
