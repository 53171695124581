import { lazy }    from "react";
import { connect } from "react-redux";

export const modalsList = {
	NewFileModal           : lazy(() => import("./NewFileModal")),
	NewFolderModal         : lazy(() => import("./NewFolderModal")),
	EditDirentModal        : lazy(() => import("./EditDirentModal")),
	MoveFolderModal        : lazy(() => import("./MoveFolderModal")),
	PreviewFileModal       : lazy(() => import("./PreviewFileModal")),
	FolderPermissionsModal : lazy(() => import("./FolderPermissionsModal")),
	GroupsModal            : lazy(() => import("./GroupsModal")),
	AddUserModal           : lazy(() => import("./AddUserModal")),
	ConfirmationModal      : lazy(() => import("./ConfirmationModal")),
	UserDetailModal        : lazy(() => import("./UserDetailModal")),
	AnnouncementModalBody  : lazy(() => import("./AnnouncementModalBody")),
	AnnouncementEdit       : lazy(() => import("./AnnouncementEdit")),
};

const Modals = ({ props, modals }) => (
	<>
		{ Object.entries(modals).map(([key, modalIsOpen]) => {
			const Component = modalsList[key] || <></>;

			return (
				<Component
					key={key}
					open={modalIsOpen}
					{...props}
				/>
			);
		}) }
	</>
);

const mapStateToProps = ({ _modalsSlice }) => ({
	props  : _modalsSlice?.props ?? {},
	modals : _modalsSlice?.modals ?? {},
});

export default connect(mapStateToProps)(Modals);
